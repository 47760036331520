























































































































































































import { Button, Cell, CellGroup, Dialog, Field, Icon, Popup, Step, Steps, Toast } from "vant";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

import DeviceCabinetBind from "@/views/device/cabinet-bind.vue";
import BusinessPicker from "@/components/picker/business-picker.vue";
import SitePicker from "@/components/picker/site-picker.vue";

@Component({
  components: {
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    DeviceCabinetBind,
    BusinessPicker,
    SitePicker
  }
})
export default class DeviceInstall extends Vue {
  cabinetId = 0;
  cabinetQrCode = "";
  businessId = 0;
  siteId = 0;

  cabinet = null;

  showCabinetPickPopup = false;
  showCabinetBind = false;
  cabinetBindId = 0;
  cabinetQrCodeDialog = {
    show: false,
    qrCode: ""
  };
  showRoomsPopup = false;
  showBusinessPicker = false;
  showSitePicker = false;

  // 生命周期
  created() {
    this.cabinetId = Number(this.$route.params.cabinetId) || 0;
    this.$readyWx(this.$axios, ["scanQRCode"]);
    if (this.cabinetId) {
      this.getData(this.cabinetId);
    }
  }

  // logic
  getData(cabinetId: number, qrCode?: string) {
    this.$axios.post("/api/manage/cabinet/getInstallInfo", { cabinetId: cabinetId, qrCode: qrCode }).then(res => {
      const data = res.data.data;
      this.cabinetId = data.id;
      this.cabinetQrCode = data.qrCode;
      this.businessId = data.businessId;
      this.siteId = data.siteId;
      this.cabinet = data;
    });
  }
  newCabinetBind() {
    this.cabinetBindId = 0;
    this.showCabinetBind = true;
  }
  toCabinetBind() {
    if (this.cabinetId <= 0) {
      Toast.fail("请先选择设备");
      return;
    }
    this.cabinetBindId = this.cabinetId;
    this.showCabinetBind = true;
  }
  toCabinetBindByQrCode(qrCode: string) {
    let code;
    if (qrCode.indexOf("cabinet?c=") !== -1) {
      code = qrCode.split("?c=")[1];
    } else {
      code = qrCode;
    }
    this.getData(0, code);
  }
  bindBusiness(businessId: number) {
    this.$axios.post("/api/manage/cabinet/bindBusiness", { qrCode: this.cabinetQrCode, businessId: businessId }).then(() => {
      Toast.success("操作成功");
      this.getData(this.cabinetId, this.cabinetQrCode);
    });
  }
  unbindBusiness() {
    Dialog.confirm({
      message: "您确定要解绑运营商吗?"
    }).then(() => this.bindBusiness(0));
  }
  bindSite(siteId: number) {
    this.$axios.post("/api/manage/cabinet/editCabinet", { cabinetId: this.cabinetId, siteId: siteId }).then(() => {
      Toast.success("操作成功");
      this.getData(this.cabinetId, this.cabinetQrCode);
    });
  }

  // ui
  @Watch("showCabinetBind")
  onShowCabinetBindChange(v: boolean) {
    if (!v) {
      this.getData(this.cabinetId, this.cabinetQrCode);
    }
  }
  closeCabinetBind(cabinetId: number) {
    this.cabinetId = cabinetId;
    this.showCabinetBind = false;
  }
  onPickBusiness(business: { id: number; name: string }) {
    this.bindBusiness(business.id);
  }
  onPickSite(site: { id: number; name: string }) {
    this.bindSite(site.id);
  }
  get step() {
    let step = -1;
    if (this.cabinetId > 0) {
      step += 2;
    } else {
      return step;
    }
    if (this.businessId > 0) {
      step++;
    } else {
      return step;
    }
    if (this.siteId > 0) {
      step++;
    } else {
      return step;
    }
    return step;
  }
}
